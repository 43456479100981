import React from 'react';

// Material components
// import { SvgIcon } from '@material-ui/core';

export default function EmptyIcon(props) {
  return (
    <svg width="130" height="147" viewBox="0 0 130 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M54.5375 42.6075C56.545 41.81 58.83 41.26 61.365 41.005C62.0525 40.9375 62.5525 40.325 62.485 39.6375C62.415 38.9525 61.8025 38.45 61.1175 38.5175C58.33 38.7975 55.82 39.4075 53.615 40.285C52.9725 40.5375 52.66 41.265 52.915 41.9075C53.17 42.5475 53.8975 42.8625 54.5375 42.6075Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M67.49 40.9575C70.445 41.18 73.15 41.2275 75.62 41.1225C76.31 41.095 76.8475 40.5125 76.8175 39.8225C76.79 39.1325 76.205 38.5975 75.5175 38.625C73.135 38.725 70.5275 38.6775 67.6775 38.4625C66.99 38.4125 66.39 38.9275 66.3375 39.615C66.285 40.305 66.8025 40.905 67.49 40.9575Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M82.37 40.3825C85.285 39.835 87.7575 39.025 89.835 38.0275C90.4575 37.7275 90.72 36.98 90.42 36.36C90.1225 35.7375 89.375 35.475 88.7525 35.7725C86.8475 36.6875 84.58 37.425 81.9075 37.9275C81.23 38.055 80.7825 38.7075 80.91 39.3875C81.0375 40.065 81.6925 40.51 82.37 40.3825Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M94.93 34.555C97.17 32.3925 98.485 29.9025 99.0775 27.37C99.235 26.6975 98.815 26.025 98.145 25.8675C97.4725 25.71 96.8 26.13 96.6425 26.8C96.1525 28.9025 95.0525 30.9625 93.195 32.755C92.6975 33.235 92.6825 34.0275 93.1625 34.5225C93.6425 35.02 94.4325 35.035 94.93 34.555Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M98.4825 18.7625C97.49 16.1275 95.8075 13.89 93.7575 12.475C93.19 12.085 92.4125 12.2275 92.02 12.795C91.6275 13.3625 91.77 14.1425 92.34 14.535C93.9975 15.6775 95.34 17.5075 96.1425 19.6425C96.385 20.2875 97.105 20.615 97.7525 20.3725C98.3975 20.13 98.725 19.4075 98.4825 18.7625Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M65.9125 12.47C67.285 6.03 72.755 2.8975 76.7 2.875C77.995 2.8675 79.1175 3.1725 79.7 3.9525C80.045 4.415 80.15 5.035 80.0175 5.795C79.9 6.475 80.3575 7.1225 81.0375 7.24C81.7175 7.3575 82.365 6.9 82.4825 6.22C82.7525 4.6575 82.4125 3.405 81.7025 2.4575C80.71 1.125 78.89 0.362499 76.6875 0.374999C71.885 0.402499 65.1375 4.11 63.4675 11.95C63.3225 12.625 63.7525 13.2875 64.4275 13.4325C65.1025 13.5775 65.7675 13.145 65.9125 12.47Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M63.4775 12.475C65.17 20.275 71.8925 23.965 76.6825 23.995C79.055 24.0075 80.9775 23.125 81.9175 21.5975C82.485 20.6775 82.7325 19.505 82.4675 18.0725C82.1425 16.31 80.9825 14.0525 78.555 11.3725C78.0925 10.86 77.3 10.82 76.79 11.2825C76.2775 11.7475 76.2375 12.5375 76.7025 13.05C78.3925 14.9175 79.395 16.51 79.835 17.8475C80.1675 18.8675 80.16 19.685 79.79 20.2875C79.455 20.83 78.855 21.1575 78.1325 21.3375C77.225 21.5625 76.1375 21.545 74.99 21.3025C71.3225 20.5275 67.1175 17.46 65.9225 11.945C65.775 11.27 65.11 10.8425 64.435 10.9875C63.76 11.135 63.3325 11.8 63.4775 12.475Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M64.36 14.71H81.0975C82.4775 14.71 83.5975 13.59 83.5975 12.21C83.5975 10.83 82.4775 9.71 81.0975 9.71H64.36C62.98 9.71 61.86 10.83 61.86 12.21C61.86 13.59 62.98 14.71 64.36 14.71Z" fill="#0065C1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 112.3V126.323C13.75 129.083 15.56 131.515 18.205 132.307C28.25 135.322 56.505 143.8 63.205 145.808C64.375 146.16 65.625 146.16 66.795 145.808C73.495 143.8 101.75 135.322 111.795 132.307C114.44 131.515 116.25 129.083 116.25 126.323C116.25 119.648 116.25 106.33 116.25 106.33C116.25 105.64 115.69 105.08 115 105.08C114.31 105.08 113.75 105.64 113.75 106.33V126.323C113.75 127.978 112.662 129.438 111.077 129.915C101.033 132.928 72.775 141.405 66.0775 143.415C65.375 143.625 64.625 143.625 63.9225 143.415L18.9225 129.915C17.3375 129.438 16.25 127.978 16.25 126.323V112.3C16.25 111.61 15.69 111.05 15 111.05C14.31 111.05 13.75 111.61 13.75 112.3Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.84 83.975C10.84 83.975 4.51 93.36 1.275 98.155C0.617499 99.13 0.454999 100.36 0.839999 101.472C1.2225 102.585 2.105 103.455 3.225 103.817L48.2125 118.435C49.8025 118.95 51.54 118.355 52.4775 116.972C55.83 112.022 66.035 96.9675 66.035 96.9675C66.255 96.6425 66.31 96.2325 66.1825 95.86C66.055 95.4875 65.76 95.1975 65.385 95.0775L15.385 78.8525C14.73 78.64 14.025 79 13.81 79.6575C13.5975 80.3125 13.9575 81.0175 14.615 81.2325L63.0325 96.9425L50.4075 115.57C50.095 116.03 49.515 116.23 48.985 116.057L3.9975 101.44C3.625 101.317 3.33 101.03 3.2025 100.657C3.075 100.287 3.13 99.8775 3.3475 99.5525L12.9125 85.3725C13.3 84.8 13.1475 84.0225 12.575 83.6375C12.005 83.25 11.2275 83.4025 10.84 83.975Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M107.503 81.16L64.615 95.0775C63.9575 95.29 63.5975 95.995 63.81 96.6525C64.025 97.3075 64.73 97.6675 65.385 97.455L108.273 83.54C108.93 83.3275 109.29 82.62 109.078 81.965C108.863 81.3075 108.158 80.9475 107.503 81.16Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M67.505 102.19C67.505 102.19 74.7575 112.89 77.5225 116.972C78.46 118.355 80.1975 118.95 81.7875 118.435C89.16 116.04 117.277 106.902 126.775 103.817C127.895 103.455 128.777 102.585 129.16 101.472C129.545 100.36 129.382 99.13 128.725 98.155C124.805 92.3425 116.037 79.3425 116.037 79.3425C115.65 78.7725 114.872 78.62 114.3 79.005C113.73 79.3925 113.577 80.17 113.962 80.7425C113.962 80.7425 122.732 93.74 126.652 99.5525C126.87 99.8775 126.925 100.287 126.797 100.657C126.67 101.03 126.375 101.317 126.002 101.44L81.015 116.057C80.485 116.23 79.905 116.03 79.5925 115.57L69.575 100.788C69.1875 100.215 68.41 100.068 67.8375 100.455C67.2675 100.84 67.1175 101.62 67.505 102.19Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.98 78.76L65.3875 64.955C65.76 64.8325 66.055 64.5425 66.1825 64.17C66.31 63.7975 66.255 63.3875 66.0325 63.0625C66.0325 63.0625 57.0525 49.855 53.93 45.2625C52.9925 43.885 51.2575 43.29 49.67 43.805L4.68 58.4225C3.56 58.7875 2.6775 59.655 2.2925 60.77C1.91 61.8825 2.0725 63.11 2.73 64.0875C6.34 69.44 13.9625 80.7425 13.9625 80.7425C14.35 81.3125 15.1275 81.465 15.7 81.0775C16.27 80.6925 16.4225 79.915 16.0375 79.3425C16.0375 79.3425 8.4125 68.0425 4.8025 62.69C4.5825 62.3625 4.53 61.955 4.6575 61.5825C4.785 61.2125 5.08 60.9225 5.4525 60.8L50.4425 46.1825C50.9725 46.01 51.55 46.21 51.8625 46.6675L63.03 63.0925L22.2075 76.3825C21.55 76.595 21.1925 77.3025 21.405 77.9575C21.62 78.6125 22.325 78.9725 22.98 78.76Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M64.6125 64.955L114.612 81.23C115.142 81.4025 115.725 81.205 116.037 80.7425C116.037 80.7425 123.66 69.44 127.27 64.0875C127.927 63.11 128.09 61.8825 127.707 60.77C127.322 59.655 126.44 58.7875 125.32 58.4225L80.33 43.805C78.7425 43.29 77.0075 43.885 76.07 45.2625L67.325 58.1225C66.9375 58.695 67.085 59.4725 67.655 59.86C68.2275 60.2475 69.005 60.1 69.3925 59.53L78.1375 46.6675C78.45 46.21 79.0275 46.01 79.5575 46.1825L124.547 60.8C124.92 60.9225 125.215 61.2125 125.342 61.5825C125.47 61.955 125.417 62.3625 125.197 62.69L114.49 78.5625L65.3875 62.5775C64.73 62.3625 64.025 62.7225 63.8125 63.38C63.5975 64.035 63.9575 64.74 64.6125 64.955Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M102.868 80.0375L65 92.3225L27.1325 80.0375L65 67.71L102.868 80.0375Z" fill="#0065C1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M52.495 63.65C51.8225 62.7025 51.245 61.75 50.76 60.7975C50.4475 60.1825 49.695 59.9375 49.08 60.2525C48.465 60.565 48.22 61.3175 48.5325 61.9325C49.07 62.99 49.71 64.0475 50.4575 65.1C50.8575 65.6625 51.6375 65.7925 52.2 65.395C52.7625 64.995 52.895 64.2125 52.495 63.65Z" fill="#738BAB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M49.0275 55.5975C48.8025 54.1725 48.7925 52.78 48.99 51.445C49.09 50.7625 48.6175 50.1275 47.935 50.0275C47.2525 49.9275 46.6175 50.4 46.515 51.0825C46.285 52.6575 46.2925 54.3025 46.5575 55.985C46.665 56.6675 47.305 57.1325 47.9875 57.025C48.6675 56.9175 49.135 56.2775 49.0275 55.5975Z" fill="#738BAB"/>
    </svg>
  );
}
