/* eslint-disable quotes */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import _ from 'underscore';
import { makeStyles } from '@material-ui/styles';
import { EmptyIcon } from 'icons';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

// import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '0 !important'
  },
  inner: {
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  emptyContainer: {
    textAlign: 'center !important',
    minHeight: 100
  }
}));

const RankingTable = props => {

  const { className, rankings, titleCard, ...rest } = props;

  // console.log('rankings: ', rankings);

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        style={{
          padding: 16,
          fontWeight: 500,
          fontSize: 20,
          color: '#1A1A1A'
        }}
      >
        {titleCard}
      </div>
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>暫定順位</TableCell>
                  <TableCell>チーム名</TableCell>
                  <TableCell>距離点</TableCell>
                  <TableCell>タイム</TableCell>
                  <TableCell>result.json</TableCell>
                  <TableCell>capture.mp4</TableCell>
                  <TableCell>autoware.log</TableCell>
                  <TableCell>rosbag2.tar.gz</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rankings.map(ranking => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={ranking.id}
                  >
                    <TableCell>#{ranking.ranking}</TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">
                          {ranking.group_name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{(ranking.distanceScore).toFixed(2)}</TableCell>
                    <TableCell>{(ranking.lapTime).toFixed(2)}</TableCell>
                    <TableCell>
                      <a
                        download
                        href={ranking?.downloadLink}
                        target="_blank"
                      >score-result</a>
                    </TableCell>
                    <TableCell>
                      <a
                        download
                        href={ranking?.downloadMp4}
                        target="_blank"
                      >capture-mp4</a>
                    </TableCell>
                    <TableCell>
                      <a
                        download
                        href={ranking?.downloadLog}
                        target="_blank"
                      >autoware.log</a>
                    </TableCell>
                    <TableCell>
                      <a
                        download
                        href={ranking?.downloadRosbag2}
                        target="_blank"
                      >rosbag data</a>
                    </TableCell>
                  </TableRow>
                ))}

                {
                  !Array.isArray(rankings) || !rankings.length &&
                  <TableRow>
                    <TableCell
                      className={classes.emptyContainer}
                      colSpan={5}
                    >
                      <EmptyIcon />
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

RankingTable.propTypes = {
  className: PropTypes.string,
  rankings: PropTypes.array.isRequired
};

export default RankingTable;
