import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Divider,
  Button,
  TextField,
  Snackbar
} from '@material-ui/core';
import {
  Alert,
} from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, onRequested, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    currentPassword: '',
    password: '',
    confirm: ''
  });

  const [snackState, setSnackState] = useState({
    open: false,
    message: '',
    type: 'info'
  })

  const showSnack = (message, type) => {
    setSnackState({
      message,
      type,
      open: true,
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackState({
      open: false,
    })
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const validatePassword = () => {
    if (values.password && values.confirm) {
      if (values.password !== values.confirm) {
        return 'パスワードが一致しません。'
      } else if (values.password.length < 6) {
        return 'パスワードが短すぎます。'
      }
    }
    return ''
  }

  const onClick = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, values.currentPassword, values.password);
      })
      .then(data => {
        showSnack('パスワードが変更されました。', 'success')
      })
      .catch(err => {
        showSnack(err.message, 'error')
      });
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      style={{ width: '360px' }}>
      <form>
        <CardHeader
          subheader="パスワードは8文字以上で、大文字小文字数字を含む必要があります。"
          title="パスワードの変更"
        />
        <CardContent>
          <TextField
            fullWidth
            label="今のパスワード"
            name="currentPassword"
            onChange={handleChange}
            type="password"
            value={values.currentPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="新しいパスワード"
            name="password"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.password}
            variant="outlined"
            error={validatePassword()}
          />
          <TextField
            fullWidth
            label="新しいパスワードの確認"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.confirm}
            variant="outlined"
            error={validatePassword()}
            helperText={validatePassword()}
          />
        </CardContent>
        <CardActions>
          <Button
            style={{ width: '50%', marginLeft: '0.5rem' }}
            // color="primary"
            variant="outlined"
            onClick={() => props.closeModalProfile()}>
            キャンセル
          </Button>
          <Button
            style={{ width: '50%', marginRight: '0.5rem' }}
            color="primary"
            variant="outlined"
            disabled={
              values.currentPassword.length === 0 ||
              values.password.length === 0 ||
              values.confirm.length === 0 ||
              validatePassword().length !== 0
            }
            onClick={onClick}>
            変更
          </Button>
        </CardActions>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackState.open}
        autoHideDuration={3000}>
        <Alert onClose={handleClose} severity={snackState.type}>
          {snackState.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
