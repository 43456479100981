/* eslint-disable quotes */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import moment from 'moment';
import { StatusAlert } from 'components';
// import { getInitials } from 'helpers';
import { STATUS, STATUS_COLORS, STATUS_TEXTS, RESULT, RESULT_TEXT, formatNumber } from '../status';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { EmptyIcon } from 'icons';
import { StatusBullet } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '0 !important'
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  emptyContainer: {
    textAlign: 'center !important',
    minHeight: 100
  },
  headerCard: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 20
  },
  selectButton: {
    margin: '8px'
  },
  textHeaderCard: {
    fontWeight: 500,
    fontSize: 20,
    color: '#1A1A1A'
  }
}));


const RevisionTable = props => {

  const { className, disabledReason, revisions, hiddenUploadBtn,  ...rest } = props;

  // console.log("revisions: ", revisions);

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getS3 = (e, bucket, key) => {
    e.preventDefault();
    Auth.currentUserCredentials()
      .then(credentials => {
        AWS.config.update({
          credentials: credentials,
          region: 'ap-northeast-1',
        })

        const s3 = new AWS.S3();
        const params = {
          Bucket: bucket,
          Key: key,
        };
        s3.getSignedUrl('getObject', params, function (err, url) {
          window.open(url);
        });
      })
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.headerCard}>
        <div
          style={{
            padding: 16,
            fontWeight: 500,
            fontSize: 20,
            color: '#1A1A1A'
          }}
        >
          アップロード履歴
        </div>
        <div
          style={hiddenUploadBtn}
        >
          <StatusBullet
            className={classes.status}
            color={STATUS_COLORS[[STATUS.READY]]}
            size="sm"
          />
          {disabledReason}
          <Button
            className={classes.selectButton}
            color="primary"
            component="label"
            onClick={() => props.handleModalUpload()}
            variant="contained"
          >
            ファイルを選択
          </Button>
        </div>
      </div>
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table className="revision-tbl">
              <TableHead>
                <TableRow>
                  <TableCell align="center">日時</TableCell>
                  <TableCell align="center">ステータス</TableCell>
                  <TableCell align="center">距離点</TableCell>
                  <TableCell align="center">タイム</TableCell>
                  <TableCell align="center">result.json</TableCell>
                  <TableCell align="center">capture.mp4</TableCell>
                  <TableCell align="center">autoware.log</TableCell>
                  <TableCell align="center">rosbag2.tar.gz</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {revisions
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map(revision => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={revision.id}
                    >
                      <TableCell align="center">
                        {moment(revision.regist_date).format(
                          'YYYY-MM-DD HH:mm'
                        )}
                      </TableCell>
                      {/* {console.log(revision)} */}
                      <TableCell align="center">
                        <div className={classes.statusContainer}>
                          <StatusAlert
                            className={classes.status}
                            color={STATUS_COLORS[revision.status]}
                            size="sm"
                            text={STATUS_TEXTS[revision.status]}
                          />
                        </div>
                      </TableCell>
                      {revision.status === STATUS.FINISHED ? (
                        <>
                          <TableCell align="center">
                            {revision?.distanceScore.toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            {revision?.lapTime.toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            <a
                              download
                              href={revision?.downloadLink}
                              target="_blank"
                            >score-result</a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              download
                              href={revision?.downloadMp4}
                              target="_blank"
                            >capture-mp4</a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              download
                              href={revision?.downloadLog}
                              target="_blank"
                            >autoware.log</a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              download
                              href={revision?.downloadRosbag2}
                              target="_blank"
                            >rosbag data</a>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </>
                      )}
                      {/* {revision.status === STATUS.FINISHED ? (
                        <>
                          <TableCell align="center">
                            {revision.score_eval?.result === RESULT.OK ? (
                              <div>{(revision.score_eval.time / 1000).toFixed(2)}秒</div>
                            ) : revision.score_eval?.result === RESULT.INVALID ||
                              revision.score_eval?.result ===
                              RESULT.GOAL_UNREACHED ||
                              revision.score_eval?.result ===
                              RESULT.NOT_PASSED_ALL_WAYPOINT ? (
                              <div>
                                失格({RESULT_TEXT[revision.score_eval?.result]})
                                </div>
                              ) : (
                                <div>
                                失格({revision.score_eval.time}秒に
                                {RESULT_TEXT[revision.score_eval?.result]})
                              </div>
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell />
                        </>
                      )} */}
                    </TableRow>
                  ))}
                {!Array.isArray(revisions) || !revisions.length &&
                  <TableRow>
                    <TableCell
                      className={classes.emptyContainer}
                      colSpan={4}
                    >
                      <EmptyIcon />
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      {revisions.length > 0 &&
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={revisions.length}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to}行目 / ${count !== -1 ? count : `more than ${to}`
              }行`
            }
            labelRowsPerPage="表示する行数"
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      }
    </Card>
  );
  // { revision.scores ? (<TableCell>{revision.total_score.toFixed(2)} 秒</TableCell>) : (<TableCell>-</TableCell>) }
};

RevisionTable.propTypes = {
  className: PropTypes.string,
};

export default RevisionTable;
