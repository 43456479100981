import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Button,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Grid } from '@material-ui/core';
import { StatusBullet } from 'components';
import { STATUS, STATUS_COLORS, STATUS_TEXTS, RESULT_TEXT, RESULT, COURSE_NAME } from '../status';
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  root: {},
  listTitle: {
    fontSize: 14,
    marginLeft: 20,
  },
  listText: {
    marginLeft: theme.spacing(4),
    marginBottom: 12,
  },
  statusBullet: {
    marginRight: theme.spacing(2),
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  contentItem: {
    display: 'flex',
  },
  changeButton: {
    fontSize: 13,
    fontWeight: 500,
    height: 25,
    width: 100,
    color: '#FFFFFF'
  }
}));


const AccountProfile = props => {
  const { className, status, ...rest } = props;

  const classes = useStyles();

  const raiseError = () => {
    throw new URIError('test!');
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/* <CardHeader
        title="アップロード履歴"
      /> */}
      <div
        style={{
          padding: 16,
          fontWeight: 500,
          fontSize: 20,
          color: '#1A1A1A'
        }}
      >
        アップロード履歴
      </div>
      <CardContent>
        <div className={classes.contentRow}>
          {/* <div className={classes.contentItem}>
            <Typography
              className={classes.listTitle}
              color="textSecondary"
              gutterBottom
            >
              最新の結果
            </Typography>
            <Typography
              className={classes.listText}
              component="h2"
              variant="h5"
            >
              {
                status.status === STATUS.FINISHED
                  ? status.score
                    ? (status.score.result === RESULT.OK
                      ? (<>{(status.score.time / 1000).toFixed(2)}秒</>)
                      : status.score.result === RESULT.INVALID
                        ? (<>結果なし</>)
                        : (<>{RESULT_TEXT[status.score.result]}</>))
                    : (<>結果なし</>)
                  : (<>-</>)
              }
            </Typography>
          </div> */}
          <div className={classes.contentItem}>
            <Typography
              className={classes.listTitle}
              color="textSecondary"
              gutterBottom
            >
              ステータス
            </Typography>
            <div
              style={{
                marginLeft: 50,
                fontWeight: 600,
                fontSize: 14,
                color: '#1A1A1A'
              }}
            >
              <StatusBullet
                className={classes.statusBullet}
                color={STATUS_COLORS[status.status]}
                size="md"
              />
              {STATUS_TEXTS[status.status]}
            </div>
          </div>
        </div>
        {/* <div>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={8}
            >
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={4}
                >
                  <div className={classes.contentItem}>
                    <div style={{ marginRight: 20 }}>
                      <Typography
                        className={classes.listTitle}
                        color="textSecondary"
                        gutterBottom
                      >
                        コース
                      </Typography>
                    </div>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <div>
                            <span style={{ fontSize: 16, fontWeight: 700 }}>チャレンジコース:</span>初心者向けのコースです。シナリオ内に走行を妨げるイベントが２つ配置されています。
                          </div>
                          <div
                            style={{ marginTop: 20 }}
                          >
                            <span style={{ fontSize: 16, fontWeight: 700 }}>アドバンストコース:</span>上級者向けのコースです。チャレンジコースに加えてイベントが２つ追加されています。
                          </div>
                        </React.Fragment>
                      }
                    >
                      <ErrorOutlineIcon />
                    </HtmlTooltip>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={8}
                >
                  <div className={classes.contentItem}>
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: '#1A1A1A'
                      }}
                    >
                      {COURSE_NAME[props.courseStatus]}
                    </div>
                    <div style={{ marginLeft: 20 }}>
                      <Button
                        className={classes.changeButton}
                        color="primary"
                        onClick={() => props.showModalSwitch()}
                        variant="contained"
                      >
                        変更する
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div> */}
      </CardContent>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
