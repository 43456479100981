import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { withAuthenticator } from 'aws-amplify-react';

import { Notifications, Password } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const Settings = (props) => {
  const classes = useStyles();
  const closeModalProfile = () => {
    props.closeModal()
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={4} xs={12}>
          <Password closeModalProfile={closeModalProfile} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings

