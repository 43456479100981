import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Typography
        style={{fontFamily: 'Inter'}}
        variant="body1"
      >
        <span>
          &copy;{' '}
          2023 {' '}
        </span>
        Synesthesias Inc.
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
