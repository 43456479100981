import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    flexGrow: 0,
    flexShrink: 0
  },
  statusAlert: {
    borderRadius: 99,
  },
  sm: {
    // height: theme.spacing(5)
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12
  },
  md: {
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  lg: {
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  neutral: {
    backgroundColor: theme.palette.neutral
  },
  primary: {
    backgroundColor: theme.palette.primary.main
  },
  info: {
    backgroundColor: theme.palette.info.main
  },
  warning: {
    backgroundColor: theme.palette.warning.main
  },
  danger: {
    backgroundColor: '#FBEFEF',
    borderWidth: 1,
    borderColor: '#E5AAAA',
    borderStyle: 'solid',
  },
  success: {
    backgroundColor: '#EBF8EC',
    borderWidth: 1,
    borderColor: '#A2C1A3',
    borderStyle: 'solid',
  }
}));

const StatusBullet = props => {
  const { text, className, size, color, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(
        {
          [classes.root]: true,
          [classes.statusAlert]: true,
          [classes[size]]: size,
          [classes[color]]: color
        },
        className
      )}
    >
      {text}
    </div>
  );
};

StatusBullet.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'neutral',
    'primary',
    'info',
    'success',
    'warning',
    'danger'
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

StatusBullet.defaultProps = {
  size: 'md',
  color: 'default'
};

export default StatusBullet;
