/* eslint-disable react/sort-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-set-state */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Snackbar,
  LinearProgress,
  Typography
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withStyles } from '@material-ui/core/styles';
import { Auth, Storage } from 'aws-amplify';
import PropTypes from 'prop-types';

const styles = {
  card: {
    margin: 48,
    height: 128
  },
  actions: {
    display: 'flex',
    marginBottom: 20
  },
  progress: {
    marginTop: 10,
    marginBottom: 10
  },
  selectButton: {
    marginLeft: 10,
    marginRight: 10
  },
  uploadButton: {
    marginLeft: 20,
    marginRight: 20
  },
  statusText: {
    fontSize: 14,
    textAlign: 'right'
  },
  status: {
    flex: 1,
    marginLeft: 20,
    marginRight: 10
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0
  },
  titleModalCard: {
    fontWeight: 700,
    padding: 12
  }
};

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

class UploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 内部のステート
      isUploading: false,
      uploadProgress: null,
      file: null,
      // スナックバー関連
      message: null,
      open: false,
      uploading: false
    };
    this.fileInput = React.createRef();

    this.handleOnChange = this.handleOnChange.bind(this);
    this.upload = this.upload.bind(this);
    this.closeSnack = this.closeSnack.bind(this);
    this.showSnack = this.showSnack.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
    this.getButtonUploadStyle = this.getButtonUploadStyle.bind(this);
    this.getStyleLineProgress = this.getStyleLineProgress.bind(this);
    this.getStyleBtnSumitUpload = this.getStyleBtnSumitUpload.bind(this);
    this.getStatusDisabledBtnSubmit = this.getStatusDisabledBtnSubmit(this);
    this.getStyleBtnCloseModal = this.getStyleBtnCloseModal.bind(this);
  }

  getButtonUploadStyle() {
    if (this.state.isUploading) {
      return {
        display: 'none'
      };
    }
    if (!this.state.file) {
      return {
        backgroundColor: '#D9E8F4'
      };
    }
    return {
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    };
    if (this.state.isUploading) {
      return {
        display: 'none'
      };
    }
    return {
      display: 'block',
      width: 140
    };
  }
  getStyleLineProgress() {
    if (this.state.isUploading) {
      return {
        display: 'block'
      };
    }
    return {
      display: 'none'
    };
  }

  getStyleBtnSumitUpload() {
    if (this.state.isUploading) {
      return {
        display: 'none'
      };
    }
    return {
      display: 'flex'
    };
  }
  getStyleBtnSumitUpload_1() {
    if (this.state.isUploading) {
      return {
        display: 'none'
      };
    }
    return {
      display: 'flex'
    };
  }
  getStatusDisabledBtnSubmit() {
    if (this.state.file) {
      return false;
    }
    return true;
  }
  getStyleBtnSumitUploadCancel() {
    if (this.state.isUploading) {
      return {
        backgroundColor: '#F0F8FF',
        display: 'block'
      };
    }
    return {
      display: 'none'
    };
  }
  getStyleBtnCloseModal() {
    if (this.state.isUploading) {
      return {
        display: 'none'
      };
    }
    return {
      display: 'block'
    };
  }
  // snack bar
  showSnack(message) {
    this.setState({
      open: true,
      message: message
    });
  }
  closeSnack() {
    this.setState({ open: false });
  }

  // ファイル選択の削除
  handleOnDelete() {
    this.setState({ file: null });
    this.fileInput.current.value = '';
  }
  // ファイル選択の変更
  handleOnChange() {
    const files = this.fileInput.current.files;
    console.log('files:', files);
    if (files.length === 0) {
      this.setState({ file: null });
    } else {
      this.setState({ file: files[0] });
    }
  }

  // amplifyでのアップロード関数
  upload() {
    if (this.state.file) {
      this.setState({ isUploading: true });
      this.props.onUploadStarted();
      this.showSnack('Started uploading');

      const uploadPath =
        this.props.uploadSubDir +
        '/' +
        String(new Date().getTime()) +
        this.state.file.name;
      Storage.put(uploadPath, this.state.file, {
        level: 'private',
        progressCallback: progress => {
          this.setState({ isUploading: true, uploadProgress: progress });
        }
      })
        .then(() => {
          this.setState({ isUploading: false, uploadProgress: null });
          this.showSnack('アップロード完了');
          this.props.onUploadFinished();
        })
        .catch(() => {
          this.setState({ isUploading: false, uploadProgress: null });
          this.showSnack('Error occured while uploading');
          this.props.onUploadFailed();
        });
    }
  }

  cancelRequestUpload() {
    const abortController = new AbortController();
    console.log('cancel request upload', abortController);
    abortController.abort();
  }

  render() {
    const { classes, uploadable, disabledReason } = this.props;
    return (
      <>
        {/* <CardHeader
          className={classes.titleModalCard}
          title="ソースコードのアップロード"
        /> */}
        <div className={classes.titleModalCard}>ソースコードのアップロード</div>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2">
          ソースコードのアップロード方法については、
            <a href="https://github.com/AutomotiveAIChallenge/aichallenge2023-racing">
          aichallenge2023-racing</a>
          をご参照ください。
          </Typography>
          <Typography
            style={{marginTop: 10}}
            variant="body2"
          >
          提出前にREADMEに従いaichallenge.launch.xmlを起点にシミュレーションを実行出来ることをご確認ください。
          </Typography>
        </CardContent>
        <CardContent>
          {/* <Button
            className={classes.selectButton}
            variant="contained"
            // disabled={!uploadable || this.state.isUploading}
            color="primary"
            component="label">
            ファイルを選択
            <input
              type="file"
              accept={['application/gzip', 'application/x-gzip']}
              style={{ display: 'none' }}
              onChange={this.handleOnChange}
              ref={this.fileInput}
            />
          </Button> */}
          {this.state.file && (
            <Chip
              label={this.state.file.name}
              onDelete={
                !this.state.isUploading && uploadable
                  ? this.handleOnDelete
                  : undefined
              }
            />
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <div className={classes.status}>
            <div className={classes.progress}>
              <Button
                className={classes.selectButton}
                component="label"
                disabled={!uploadable || this.state.isUploading}
                style={this.getButtonUploadStyle()}
                variant="contained"
              >
                ブラウズ
                <input
                  accept={['application/gzip', 'application/x-gzip']}
                  onChange={this.handleOnChange}
                  ref={this.fileInput}
                  style={{ display: 'none' }}
                  type="file"
                />
              </Button>
              <LinearProgress
                style={this.getStyleLineProgress()}
                value={
                  this.state.uploadProgress
                    ? (this.state.uploadProgress.loaded /
                        this.state.uploadProgress.total) *
                      100
                    : 0
                }
                variant="determinate"
              />
            </div>
            <Typography
              className={classes.statusText}
              color="textSecondary"
            >
              {uploadable
                ? this.state.file
                  ? this.state.isUploading
                    ? this.state.uploadProgress
                      ? `${Math.round(
                        this.state.uploadProgress.loaded / 1000000
                      )} MB / ${Math.round(
                        this.state.uploadProgress.total / 1000000
                      )} MB`
                      : 'アップロード開始中'
                    : 'アップロードできます'
                  : 'ファイルを選択してください'
                : ''}
              {/* : disabledReason} */}
            </Typography>
          </div>
          <Button
            className={classes.uploadButton}
            onClick={() => this.props.handleCloseModalUpload()}
            style={this.getStyleBtnSumitUpload()}
            variant="contained"
          >
            閉じる
          </Button>
          <Button
            className={classes.uploadButton}
            color="primary"
            // disabled={!uploadable || this.state.isUploading || !this.state.file}
            disabled={!uploadable || !this.state.file}
            onClick={this.upload}
            startIcon={<CloudUploadIcon />}
            style={this.getStyleBtnSumitUpload()}
            variant="contained"
          >
            アップロード
          </Button>
          <Button
            className={classes.uploadButton}
            onClick={
              (() => this.cancelRequestUpload,
              this.props.handleCloseModalUpload)
            }
            style={this.getStyleBtnSumitUploadCancel()}
            variant="contained"
          >
            キャンセル
          </Button>
        </CardActions>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={2000}
          message={this.state.message}
          onClose={this.closeSnack}
          open={this.state.open}
        />
      </>
    );
  }
}

UploadForm.propTypes = {
  // アップロード可能か？
  uploadable: PropTypes.bool,
  // 無効になっている理由(ステートに表示される)
  disabledReason: PropTypes.string,
  // アップロード先のサブディレクトリ
  uploadSubDir: PropTypes.string,
  // コールバック関数
  onUploadStarted: PropTypes.func,
  onUploadFinished: PropTypes.func,
  onUploadFailed: PropTypes.func
};

export default withStyles(styles)(UploadForm);
