/* eslint-disable indent */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/no-set-state */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/prop-types */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { CardContent, CardHeader, Grid } from '@material-ui/core';

import {
  RankingTable,
  Upload,
  RevisionTable,
  UserStatus,
  StatusCard,
  ChangeCourse
} from './components';
import mockData from './data';
import { withAuthenticator } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import _ from 'underscore';
import moment from 'moment-timezone';
import {
  STATUS,
  parse_state,
  RESULT,
  detail_to_result,
  DATA_SAMPLE_CHALLENGE,
  COURSE_STATUS,
  DATA_SAMPLE_ADVANCED,
  BUILD_STATE
} from './status';
import * as Sentry from '@sentry/browser';
import { Card, CardActions, Button, Modal, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  segment: {
    marginBottom: theme.spacing(2)
  },
  paper: {
    position: 'absolute',
    width: 660,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    borderRadius: theme.spacing(1)
  },
  source: {
    position: 'absolute',
    width: 432,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    borderRadius: theme.spacing(1)
  },
  btnSubmitConfirmSwitch: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 500,
    width: 60,
    height: 36,
    background: '#0065C1'
  },
  btnCancelConfirmSwitch: {
    color: '#0065C1',
    fontSize: 14,
    fontWeight: 500,
    width: 102,
    height: 36,
    background: '#F0F8FF'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

let dynamoClient = null;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploadable: false,
      reason: '',
      userListInfo: null,
      rankings: [],
      rankingAdvanced: [],
      rankingChallenge: [],
      groupRevisions: [],
      userStatus: {
        user_id: '',
        username: '',
        score: 0,
        status: STATUS.READY,
        last_regist_date: 0
      },
      revisions: [],
      test: {
        value: 100
      },
      open: false,
      openModalProfile: false,
      snackState: {
        open: false,
        message: '',
        type: 'info'
      },
      isShowSwitchMode: false,
      confirmSwitchMode: false,
      courseStatus: '1',
      courceStatusTemp: null
    };

    Auth.currentUserCredentials().then(credentials => {
      // console.log("credentials: ", credentials);
      AWS.config.update({
        credentials: credentials,
        region: 'ap-northeast-1'
      });
      dynamoClient = new AWS.DynamoDB.DocumentClient({
        region: 'ap-northeast-1',
        apiVersion: '2012-08-10'
      });

      Auth.currentUserInfo().then(user => {
        if (user && user.username) {
          getUser(user.username).then(response => {
            // console.log("response: ", response);
            this.setState({
              userListInfo: response.Item,
              courseStatus: response.Item && response.Item.course ? response.Item.course : 1
            });
          });
        }
      });

      this.update();
      const hook = setInterval(() => {
        this.update(hook);
      }, 5000);
    });
    this.updateRanking = this.updateRanking.bind(this);
    this.onUploadStarted = this.onUploadStarted.bind(this);
    this.onUploadFinished = this.onUploadFinished.bind(this);
    this.onUploadFailed = this.onUploadFailed.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getModalStyle = this.getModalStyle.bind(this);
    this.handleModalUpload = this.handleModalUpload.bind(this);
    this.handleCloseModalUpload = this.handleCloseModalUpload.bind(this);
    this.handleCloseSnack = this.handleCloseSnack.bind(this);
    this.showModalSwitch = this.showModalSwitch.bind(this);
    this.closeModalSwitch = this.closeModalSwitch.bind(this);
    this.handleChangeSource = this.handleChangeSource.bind(this);
    this.handleCloseConfirmSwitch = this.handleCloseConfirmSwitch.bind(this);
    this.handleSubmitConfirmSwitch = this.handleSubmitConfirmSwitch.bind(this);
  }

  rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  compareDate() {
    var currentDate = new Date();
    var dateValues = process.env.REACT_APP_DEV_TIME_DISABLE.split(',').map(function(item) {
      return parseInt(item);
    });
    var dateHidden = new Date(dateValues[0], dateValues[1], dateValues[2], dateValues[3], dateValues[4]);    
    // var dateHidden = new Date(2024, 1, 1, 13, 0); // month from 0
    // console.log("dateHidden: ", dateHidden);
    if (currentDate.getTime() > dateHidden.getTime()) {
      return {
        fontSize: `${16}px`,
        display: 'none'
      };
    }
    return {
      fontSize: `${16}px`
    };
  }

  showSnack(message, type) {
    this.setState(state => {
      state.snackState = {
        message,
        type,
        open: true
      };
      return state;
    });
  }

  handleCloseSnack(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState(state => {
      state.snackState.open = false;
      return state;
    });
  }

  getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  handleOpen() {
    this.setState(state => {
      state.open = true;
      return state;
    });
  }

  handleClose() {
    this.setState(state => {
      state.open = false;
      return state;
    });
  }

  handleModalUpload() {
    this.setState(state => {
      state.open = true;
      return state;
    });
  }

  handleCloseModalUpload() {
    this.setState(state => {
      state.open = false;
      return state;
    });
  }

  // each 5s call to get newest
  update(hook) {
    Auth.currentUserInfo().then(user => {
      if (user && user.username) {
        Sentry.configureScope(function(scope) {
          scope.setUser({
            id: user.id,
            username: user.username
          });
        });
        this.setState(state => {
          state.userStatus.user_id = user.id;
          state.userStatus.username = user.username;
          return state;
        });
        this.updateRanking();
      } else {
        if (hook) {
          clearInterval(hook);
        }
        Auth.signOut();
      }
    });
  }

  updateRanking() {
    fetchDbItems
      .bind(this)()
      .then(({ rankings, revisions, groupRevisions, user_list }) => {
        // console.log('rankings: ', rankings);
        // console.log('revisions: ', revisions);
        this.setState(state => {
          state.rankings = rankings;
          state.revisions = revisions;
          const { rankingAdvanced, rankingChallenge } = filterRanking(
            user_list,
            rankings
          );
          // console.log("rankingAdvanced: ", rankingAdvanced);
          state.rankingAdvanced = rankingAdvanced;
          state.rankingChallenge = rankingChallenge;
          state.groupRevisions = groupRevisions;
          const user_id = state.userStatus.username;
          const found_user = _.find(user_list, item => item.user_id == user_id);
          state.userStatus.group_id = found_user
            ? found_user.group_id
            : 'not found';
          state.userStatus.score =
            revisions.length > 0
              ? revisions[0].score_eval
                ? revisions[0].score_eval
                : null
              : null;
          state.userStatus.status =
            revisions.length > 0 ? revisions[0].status : STATUS.READY;
          state.userStatus.last_regist_date =
            revisions.length > 0 ? revisions[0].regist_date : 0;
          state.user_list = user_list;

          const { isUploadable, reason } = this.isUploadable(state);
          state.isUploadable = isUploadable;
          state.reason = reason;
          return state;
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  onUploadStarted() {
    this.setState(state => {
      state.userStatus.status = STATUS.UPLOADING;
      return state;
    });
  }
  onUploadFinished() {
    const registedDate = new Date().getTime();
    this.setState(state => {
      state.open = false;
      state.isUploadable = false;
      state.userStatus.status = STATUS.UPLOADING_FINISHED;
      state.userStatus.last_regist_date = registedDate;
      return state;
    });
    const params = {
      user_id: this.state.userListInfo.user_id,
      regist_date: registedDate,
      source_file_path: '',
      course: this.state.courseStatus
    };
    putUploadExecution(params).then(
      () => {},
      () => {}
    );
  }
  onUploadFailed() {
    this.setState(state => {
      state.userStatus.status = STATUS.READY;
      return state;
    });
  }

  isUploadable(state) {
    const valid_revision_of_today = _.filter(state.groupRevisions, item => {
      const regist_date = moment.tz(item.regist_date, 'Asia/Tokyo');
      const now = moment().tz('Asia/Tokyo');
      return regist_date.format('YYYYMMDD') === now.format('YYYYMMDD');
    });

    if (!(state.userStatus.user_id && state.userStatus.username)) {
      return { isUploadable: false, reason: 'ログインしていません' };
    }
    const userList = state.user_list;
    const groupId = state.userStatus.group_id;
    let userOfGroupUpload = [];
    for (let i = 0; i < valid_revision_of_today.length; i++) {
      const userInfo = userList.filter(
        item =>
          item.user_id == valid_revision_of_today[i].user_id &&
          item.group_id == groupId
      );
      userOfGroupUpload = [...userOfGroupUpload, ...userInfo];
    }
    // TODO: LIMIT UPLOAD PER USER > LIMIT UPLOAD PER TEAM
    if (userOfGroupUpload.length >= 10) {
      return {
        isUploadable: false,
        reason: '1日にアップロードできるのは10回までです'
      };
    }

    if (
      state.userStatus.status !== STATUS.READY &&
      state.userStatus.status !== STATUS.FINISHED &&
      state.userStatus.status !== STATUS.BUILD_ERROR &&
      state.userStatus.status !== STATUS.STARTUP_ERROR &&
      state.userStatus.status !== STATUS.RUNNING_ERROR &&
      state.userStatus.status !== STATUS.RUNNING_TIMEOUT &&
      state.userStatus.status !== STATUS.INVALID
    ) {
      return { isUploadable: false, reason: '採点中です' };
    }

    return { isUploadable: true, reason: '' };
  }

  showModalSwitch() {
    this.setState(state => {
      state.isShowSwitchMode = true;
      return state;
    });
  }
  closeModalSwitch() {
    this.setState(state => {
      state.isShowSwitchMode = false;
      return state;
    });
  }
  handleChangeSource(value) {
    this.setState(state => {
      state.confirmSwitchMode = true;
      state.courceStatusTemp = value;
      return state;
    });
  }
  handleCloseConfirmSwitch() {
    this.setState(state => {
      state.confirmSwitchMode = false;
      state.isShowSwitchMode = false;
      state.courceStatusTemp = null;
      return state;
    });
  }
  handleSubmitConfirmSwitch() {
    const prevCourseStatus = this.state.courseStatus;
    this.setState(state => {
      state.confirmSwitchMode = false;
      state.isShowSwitchMode = false;
      return state;
    });
    if (this.state.courceStatusTemp == COURSE_STATUS.ADVANCED) {
      this.setState(state => {
        state.courseStatus = state.courceStatusTemp;
        return state;
      });
    } else {
      this.setState(state => {
        state.courseStatus = state.courceStatusTemp;
        return state;
      });
    }
    putUserConfig(this.state.courceStatusTemp).then(userConfig => {});

    // scan & update all previous build to invalid
    // console.log('this.state.userStatus.user_id', this.state.userStatus.user_id);
    markInvalidBuildResult(this.state.revisions);
    // this.updateRanking();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <div className={classes.segment}>
                <div>
                  <Modal
                    aria-describedby="simple-modal-description"
                    aria-labelledby="simple-modal-title"
                    onClose={this.handleClose}
                    open={this.state.open}>
                    <div className={classes.paper} style={this.getModalStyle()}>
                      <Upload
                        disabledReason={this.state.reason}
                        handleCloseModalUpload={this.handleCloseModalUpload}
                        onUploadFailed={this.onUploadFailed}
                        onUploadFinished={this.onUploadFinished}
                        onUploadStarted={this.onUploadStarted}
                        uploadable={this.state.isUploadable}
                        uploadSubDir={this.state.userStatus.username}
                      />
                    </div>
                  </Modal>
                  <Modal
                    aria-describedby="simple-modal-description"
                    aria-labelledby="simple-modal-title"
                    onClose={this.handleClose}
                    open={this.state.confirmSwitchMode}>
                    <div className={classes.paper} style={this.getModalStyle()}>
                      <Card>
                        <CardHeader title="確認" />
                        <CardContent>
                          <div>
                            現在のコースでの順位は削除されます。更新して宜しいですか？
                          </div>
                        </CardContent>
                        <CardActions className={classes.flexEnd}>
                          <Button
                            color="primary"
                            onClick={this.handleSubmitConfirmSwitch}
                            variant="contained">
                            更新
                          </Button>
                          {/* <Button
                            className={classes.btnSubmitConfirmSwitch}
                            onClick={this.handleSubmitConfirmSwitch}
                          >
                          更新
                          </Button> */}
                          <Button
                            className={classes.btnCancelConfirmSwitch}
                            onClick={this.handleCloseConfirmSwitch}>
                            キャンセル
                          </Button>
                        </CardActions>
                      </Card>
                    </div>
                  </Modal>
                  <Modal
                    aria-describedby="simple-modal-description"
                    aria-labelledby="simple-modal-title"
                    open={this.state.isShowSwitchMode}>
                    <div
                      className={classes.source}
                      style={this.getModalStyle()}>
                      <ChangeCourse
                        courseStatus={this.state.courseStatus}
                        onChangeSource={this.handleChangeSource}
                      />
                    </div>
                  </Modal>
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    autoHideDuration={3000}
                    open={this.state.snackState.open}>
                    <Alert
                      onClose={this.handleCloseSnack}
                      severity={this.state.snackState.type}>
                      {this.state.snackState.message}
                    </Alert>
                  </Snackbar>
                </div>
                <UserStatus
                  closeModalSwitch={this.closeModalSwitch}
                  courseStatus={this.state.courseStatus}
                  showModalSwitch={this.showModalSwitch}
                  status={this.state.userStatus}
                />
              </div>
              <div>
                <RevisionTable
                  disabledReason={this.state.reason}
                  handleModalUpload={this.handleModalUpload}
                  revisions={this.state.revisions}
                  hiddenUploadBtn={this.compareDate()}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div>
                <RankingTable
                  rankings={this.state.rankingAdvanced}
                  titleCard="暫定順位表（社会人コース）"
                />
              </div>
              <div>
                <RankingTable
                  rankings={this.state.rankingChallenge}
                  titleCard="暫定順位表（学生コース）"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

function scanDynamoDB(table_name) {
  return new Promise((resolve, reject) => {
    dynamoClient.scan({ TableName: table_name }, (err, data) => {
      if (err) {
        reject(err);
      } else {
        const result = data.Items;
        resolve(result);
      }
    });
  });
}

function scanDynamoDBUser(table_name) {
  return new Promise((resolve, reject) => {
    dynamoClient.scan(
      {
        TableName: table_name,
        AttributesToGet: [
          'user_id',
          'created_at',
          'role',
          'course',
          'is_active',
          'group',
          'group_id'
        ]
      },
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          const result = data.Items;
          // console.log("result: ", result);
          resolve(result);
        }
      }
    );
  });
}

function scanBuildRecord(user_id) {
  return new Promise((resolve, reject) => {
    const params = {
      TableName: `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_build_result`,
      Key: {
        user_id: user_id
      },
      index: 'user_id-course-index'
    };
    dynamoClient.scan(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        const result = data.Items;
        resolve(result);
      }
    });
  });
}

function fetchDbItems() {
  /**
   * データベースのデータを全部もらって
   */
  const { userListInfo, courseStatus } = this.state;
  return new Promise((resolve, reject) => {
    Promise.all([
      scanDynamoDB(
        `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_build_result`
      ),
      scanDynamoDB(
        `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_execution_record`
      ),
      scanDynamoDB(
        `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_simulator_execution_result`
      ),
      scanDynamoDBUser(
        `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_user_list`
      )
    ])
      .then(results => {
        const build_result = results[0];
        // console.log('build_result: ', build_result);
        const execution_record = results[1];
        // console.log('execution_record: ', execution_record);
        const simulator_execution_result = results[2];
        // console.log('simulator_execution_result: ', simulator_execution_result);
        const user_list = results[3];
        // console.log('user_list: ', user_list);

        // console.log(getBuildRecord('simulator_build:7a378dbc-b774-4d1f-ae2f-cbf4acea8e11'));
        Auth.currentAuthenticatedUser().then(user => {
          // (1) rankings
          const rankings = parseRankings(
            build_result,
            execution_record,
            simulator_execution_result,
            user_list
          );
          // (2) revisions
          const { revisions, groupRevisions } = parseRevisions(
            user.username,
            build_result,
            execution_record,
            simulator_execution_result,
            user_list
          );
          resolve({ rankings, revisions, groupRevisions, user_list });
        });
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });
}

function parseRankings(
  build_result,
  execution_record,
  simulator_execution_result,
  user_list
) {
  // console.log('build_result', build_result);
  // console.log('execution_record', execution_record);
  // console.log('simulator_execution_result', simulator_execution_result);
  // console.log('user_list', user_list);
  const valid_user_list = user_list.map(user => user.user_id);
  const deactive_user_list = user_list
    .filter(user => user.is_active === 'Deactivated')
    .map(item => item.user_id);
    // console.log("deactive_user_list: ", deactive_user_list);
  execution_record = execution_record.filter(
    record => !deactive_user_list.includes(record.user_id)
  );
  // console.log("execution_record: ", execution_record);
  const execution_grouped_by_user = _.groupBy(
    execution_record.filter(
      execution => valid_user_list.indexOf(execution.user_id) !== -1
    ),
    item => item.user_id
  );
  // console.log("execution_grouped_by_user: ", execution_grouped_by_user);
  const latest_executions = _.map(
    execution_grouped_by_user,
    (executions, user_id) => {
      // 結合
      const joined_executions = _.map(executions, execution => {
        // そのときのビルド結果、シミュレーター実行結果を取り出す
        if (execution.build_id) {
          return {
            execution: execution,
            build: _.find(build_result, item => {
              return item.build_id == execution.build_id;
            }),
            sim: _.filter(simulator_execution_result, item => {
              return item.build_id == execution.build_id;
            })
          };
        } else {
          return {
            execution: execution,
            build: null,
            sim: null
          };
        }
      });
      // console.log('joined_executions', joined_executions);
      // 有効スコアがついているもので、最新のものを取り出す
      const executions_with_score = _.map(joined_executions, execution => {
        const score = parse_score(execution.sim ? execution.sim : null);
        const state = parse_state(
          execution.build ? execution.build.state : null,
          execution.sim ? execution.sim : null,
          null
        );
        return {
          ...execution,
          state,
          score
        };
      });
      // console.log('executions_with_score', executions_with_score);
      const finished_executions = _.filter(executions_with_score, execution => {
        return execution.state === STATUS.FINISHED;
      });
      // console.log("finished_executions: ", finished_executions);
      const found_user = _.find(user_list, item => item.user_id == user_id);
      const group_name = found_user ? found_user.group : 'not found';
      if (finished_executions.length > 0) {
        // console.log('finished_executions: ', finished_executions);
        let top_execution;
        const mergedSim = finished_executions.map(item => item.sim).flat();

        if (mergedSim.length > 0) {
          const completedResults = mergedSim.filter(
            item => item.detail && JSON.parse(item.detail).isLapCompleted
          );
          // console.log('completedResults: ', completedResults);
          if (completedResults.length > 0) {
            const maxCompleted = completedResults.reduce((max, item) => {
              const maxDetail = max.detail && JSON.parse(max.detail);
              const itemDetail = item.detail && JSON.parse(item.detail);
              if (
                itemDetail.distanceScore > maxDetail.distanceScore ||
                (itemDetail.distanceScore === maxDetail.distanceScore &&
                  itemDetail.lapTime < maxDetail.lapTime)
              ) {
                return item;
              } else {
                return max;
              }
            });
            // console.log('maxCompleted: ', maxCompleted);
            top_execution = finished_executions.find(item =>
              item.sim.includes(maxCompleted)
            );
          } else {
            const nonCompletedResults = mergedSim.filter(
              item => item.detail && !(JSON.parse(item.detail).isLapCompleted)
            );
            if (nonCompletedResults.length > 0) {
              const maxNonCompleted = nonCompletedResults.reduce(
                (max, item) => {
                  const maxDetail = max.detail && JSON.parse(max.detail);
                  const itemDetail = item.detail && JSON.parse(item.detail);
                  if (
                    itemDetail.distanceScore > maxDetail.distanceScore ||
                    (itemDetail.distanceScore === maxDetail.distanceScore &&
                      itemDetail.lapTime < maxDetail.lapTime)
                  ) {
                    return item;
                  } else {
                    return max;
                  }
                }
              );
              top_execution = finished_executions.find(item =>
                item.sim.includes(maxNonCompleted)
              );
            }
          }
        }
        // console.log('top_execution: ', top_execution);

        let distanceScore;
        let lapTime;
        let isLapCompleted;
        let maxValIndex = 0;
        if (top_execution.sim && top_execution.sim.length > 0) {
          // let arrDistanceScores = top_execution.sim
          //   .map(
          //     item => item?.detail && JSON.parse(item?.detail)?.distanceScore
          //   )
          //   .filter(i => i || i === 0)
          //   .sort((a, b) => b - a);
          // distanceScore = arrDistanceScores[0];
          // for (let i = 0; i < top_execution.sim.length; i++) {
          //   const dataParse = top_execution.sim[i].detail
          //     ? JSON.parse(top_execution.sim[i].detail)
          //     : '';
          //   if (dataParse && dataParse.distanceScore === distanceScore) {
          //     maxValIndex = i;
          //     isLapCompleted = dataParse.isLapCompleted;
          //     lapTime = dataParse.lapTime;
          //   }
          // }

          let completedLaps = top_execution.sim.filter(item => {
            let detail = item.detail && JSON.parse(item.detail);
            return detail && detail.isLapCompleted === true;
          });

          // console.log("completedLaps: ", completedLaps);

          if (completedLaps.length > 0) {
            completedLaps.sort((a, b) => {
              let detailA = JSON.parse(a?.detail);
              let detailB = JSON.parse(b?.detail);
              if (detailA.distanceScore === detailB.distanceScore) {
                return detailA.lapTime - detailB.lapTime;
              }
              return detailB.distanceScore - detailA.distanceScore;
            });
            if (completedLaps[0]?.detail) {
              distanceScore = JSON.parse(completedLaps[0]?.detail)
                .distanceScore;
              lapTime = JSON.parse(completedLaps[0]?.detail).lapTime;
            }

            for (let i = 0; i < top_execution.sim.length; i++) {
              const dataParse = top_execution.sim[i].detail
                ? JSON.parse(top_execution.sim[i].detail)
                : '';
              if (
                dataParse &&
                dataParse.distanceScore === distanceScore &&
                dataParse.lapTime === lapTime
              ) {
                maxValIndex = i;
                isLapCompleted = true;
              }
            }
          } else {
            let incompleteLaps = top_execution.sim.filter(item => {
              let detail = item.detail && JSON.parse(item.detail);
              return detail && detail.isLapCompleted === false;
            });
            incompleteLaps.sort((a, b) => {
              let detailA = JSON.parse(a?.detail);
              let detailB = JSON.parse(b?.detail);
              if (detailA.distanceScore === detailB.distanceScore) {
                return detailA.lapTime - detailB.lapTime;
              }
              return detailB.distanceScore - detailA.distanceScore;
            });
            if (incompleteLaps[0]?.detail) {
              distanceScore = JSON.parse(incompleteLaps[0]?.detail)
                .distanceScore;
              lapTime = JSON.parse(incompleteLaps[0]?.detail).lapTime;
            }

            for (let i = 0; i < top_execution.sim.length; i++) {
              const dataParse = top_execution.sim[i].detail
                ? JSON.parse(top_execution.sim[i].detail)
                : '';
              if (
                dataParse &&
                dataParse.distanceScore === distanceScore &&
                dataParse.lapTime === lapTime
              ) {
                maxValIndex = i;
                isLapCompleted = false;
              }
            }
          }
        }

        if (top_execution.score.result === RESULT.OK) {
          return {
            is_valid: true,
            id: top_execution.execution.user_id,
            user_id: top_execution.execution.user_id,
            group_name: group_name,
            build_id: top_execution.build.build_id,
            regist_date: top_execution.execution.regist_date,
            score: top_execution.score,
            distanceScore: distanceScore,
            lapTime: lapTime,
            isLapCompleted: isLapCompleted,
            downloadLink: `${process.env.REACT_APP_DEV_BUCKET_DOWNLOAD}/${
              top_execution.execution.build_id
            }/${maxValIndex + 1}/result.json`,
            downloadMp4: `${process.env.REACT_APP_DEV_BUCKET_DOWNLOAD}/${
              top_execution.execution.build_id
            }/${maxValIndex + 1}/capture.mp4`,
            downloadLog: `${process.env.REACT_APP_DEV_BUCKET_DOWNLOAD}/${
              top_execution.execution.build_id
            }/${maxValIndex + 1}/autoware.log`,
            downloadRosbag2: `${process.env.REACT_APP_DEV_BUCKET_DOWNLOAD}/${
              top_execution.execution.build_id
            }/${maxValIndex + 1}/rosbag2_autoware.tar.gz`
          };
        } else {
          return {
            is_valid: false
          };
        }
      } else {
        return {
          is_valid: false
        };
      }
    }
  );
  // console.log("latest_executions: ", latest_executions);
  // latest_executionsをscore順に並べる
  const set = new Set();
  const key = 'group_name';
  // filter and remove duplicate group name by regist_date
  const filtered_latest_executions = _.filter(
    latest_executions,
    item => item.is_valid && item.distanceScore > 0
  )
    .sort((a, b) => b.distanceScore - a.distanceScore)
    .reduce((arr, e) => {
      if (!set.has(e[key])) {
        set.add(e[key]);
        arr.push({ ...e });
      }
      return arr;
    }, []);

  // console.log('filtered_latest_executions: ', filtered_latest_executions);
  const rankings = add_rank(filtered_latest_executions);
  return rankings;
}

// function add_rank(array) {
//   const sorted_array = _.sortBy(array, item => item.distanceScore).reverse();
//   return _.map(sorted_array, elem => {
//     // this rank is 0-origin
//     const rank = _.filter(array, item =>
//       item.distanceScore === elem.distanceScore
//         ? item.lapTime > elem.lapTime
//         : item.distanceScore > elem.distanceScore
//     ).length;
//     return {
//       ...elem,
//       ranking: rank + 1
//     };
//   });
// }

function add_rank(array) {
  // console.log("array: ", array);
  const completedArray = array.filter(item => item.isLapCompleted === true);
  let incompletedArray = array.filter(item => item.isLapCompleted === false);

  // console.log("completedArray: ", completedArray);
  // console.log("incompletedArray: ", incompletedArray);

  completedArray.sort((a, b) => {
    return b.distanceScore - a.distanceScore || a.lapTime - b.lapTime;
  });

  incompletedArray.sort((a, b) => {
    return b.distanceScore - a.distanceScore || a.lapTime - b.lapTime;
  });

  // incompletedArray = incompletedArray.map(item => {
  //   return {
  //     ...item,
  //     distanceScore: 0
  //   };
  // });

  const sortedArray = completedArray.concat(incompletedArray);

  return sortedArray.map((elem, index) => {
    const rank = index + 1;
    return {
      ...elem,
      ranking: rank
    };
  });
}

function parse_score(sims) {
  if (sims === null || sims.length === 0) {
    // そもそもスコアがまだ出ていない
    return {
      result: RESULT.INVALID
    };
  } else {
    const details = sims
      .filter(sim => sim.state == '2')
      .map(sim => {
        if (sim.detail) {
          const detail = sim.detail;
          return detail;
        } else {
          return {};
        }
      });
    const results = details.map(detail_to_result);
    const valid_scores = results.filter(
      result => result.result === RESULT.OK && result.time > 0
    );
    const valid_scores_filter = valid_scores.filter(ele => ele.time > 0);
    if (valid_scores_filter && valid_scores_filter.length > 0) {
      const avg =
        valid_scores_filter.reduce((total, s) => {
          return s.time + total;
        }, 0) / valid_scores_filter.length;
      // validなスコアの最小値を出力する
      return {
        result: avg === 0 ? RESULT.INVALID : RESULT.OK,
        time: avg
      };
    } else {
      // なければ、最初の失敗理由を報告
      return results[0];
    }
  }
}

function parseRevisions(
  user_id,
  build_result,
  execution_record,
  simulator_execution_result,
  user_list
) {
  const userInfo = user_list.find(user => user.user_id === user_id);
  // find all user in a group
  const group_user_ids = user_list
    .filter(user => userInfo && user.group_name == userInfo.group_name)
    .map(user => user.user_id);

  // console.log('build_result', build_result);
  // console.log('execution_record', execution_record);
  // console.log('simulator_execution_result', simulator_execution_result);
  // 自分の実行だけ取り出す
  const my_executions = _.filter(
    execution_record,
    item => item.user_id === user_id
  );
  const group_executions = _.filter(execution_record, item =>
    group_user_ids.includes(item.user_id)
  );

  // console.log('my_executions', my_executions);
  const joined_executions = _.map(my_executions, execution => {
    // そのときのビルド結果、シミュレーター実行結果を取り出す
    if (execution.build_id) {
      return {
        execution: execution,
        build: _.find(build_result, item => {
          return item.build_id == execution.build_id;
        }),
        sim: _.filter(simulator_execution_result, item => {
          return item.build_id == execution.build_id;
        })
      };
    } else {
      return {
        execution: execution,
        build: null,
        sim: null
      };
    }
  });
  const joined_group_executions = _.map(group_executions, execution => {
    // そのときのビルド結果、シミュレーター実行結果を取り出す
    if (execution.build_id) {
      return {
        execution: execution,
        build: _.find(build_result, item => {
          return item.build_id == execution.build_id;
        }),
        sim: _.filter(simulator_execution_result, item => {
          return item.build_id == execution.build_id;
        })
      };
    } else {
      return {
        execution: execution,
        build: null,
        sim: null
      };
    }
  });
  const my_executions_with_results =
    joined_executions &&
    joined_executions.length > 0 &&
    _.map(joined_executions, execution => {
      //find highest distanceScore and lapTime
      let distanceScore;
      let lapTime;
      let maxValIndex;
      // console.log("execution: ", execution);
      if (execution.sim && execution.sim.length > 0) {
        // let arrDistanceScores = execution.sim
        //   .map(item => item?.detail && JSON.parse(item?.detail)?.distanceScore)
        //   .filter(i => i || i === 0);
        // let arrLapTimes = execution.sim
        //   .map(item => item?.detail && JSON.parse(item?.detail)?.lapTime)
        //   .filter(i => i || i === 0);
        // for (let i = 0; i < arrDistanceScores.length; i += 1) {
        //   if (
        //     distanceScore === undefined ||
        //     arrDistanceScores[i] > distanceScore ||
        //     (arrDistanceScores[i] === distanceScore &&
        //       arrLapTimes[i] < arrLapTimes)
        //   ) {
        //     distanceScore = arrDistanceScores[i];
        //     lapTime = arrLapTimes[i];
        //   }
        // }
        // for (let i = 0; i < execution.sim.length; i++) {
        //   const dataParse = execution.sim[i].detail
        //     ? JSON.parse(execution.sim[i].detail)
        //     : '';
        //   if (dataParse && dataParse.distanceScore === distanceScore) {
        //     maxValIndex = i;
        //   }
        // }

        let completedLaps = execution.sim.filter(item => {
          let detail = item.detail && JSON.parse(item.detail);
          return detail && detail.isLapCompleted === true;
        });

        if (completedLaps.length > 0) {
          completedLaps.sort((a, b) => {
            let detailA = JSON.parse(a?.detail);
            let detailB = JSON.parse(b?.detail);
            if (detailA.distanceScore === detailB.distanceScore) {
              return detailA.lapTime - detailB.lapTime;
            }
            return detailB.distanceScore - detailA.distanceScore;
          });

          if (completedLaps[0]?.detail) {
            distanceScore = JSON.parse(completedLaps[0]?.detail).distanceScore;
            lapTime = JSON.parse(completedLaps[0]?.detail).lapTime;
          }

          for (let i = 0; i < execution.sim.length; i++) {
            const dataParse = execution.sim[i].detail
              ? JSON.parse(execution.sim[i].detail)
              : '';
            if (
              dataParse &&
              dataParse.distanceScore === distanceScore &&
              dataParse.lapTime === lapTime
            ) {
              maxValIndex = i;
            }
          }
        } else {
          let incompleteLaps = execution.sim.filter(item => {
            let detail = item.detail && JSON.parse(item.detail);
            return detail && detail.isLapCompleted === false;
          });
          incompleteLaps.sort((a, b) => {
            let detailA = JSON.parse(a?.detail);
            let detailB = JSON.parse(b?.detail);
            if (detailA.distanceScore === detailB.distanceScore) {
              return detailA.lapTime - detailB.lapTime;
            }
            return detailB.distanceScore - detailA.distanceScore;
          });
          if (incompleteLaps[0]?.detail) {
            distanceScore = JSON.parse(incompleteLaps[0]?.detail).distanceScore;
            lapTime = JSON.parse(incompleteLaps[0]?.detail).lapTime;
          }

          for (let i = 0; i < execution.sim.length; i++) {
            const dataParse = execution.sim[i].detail
              ? JSON.parse(execution.sim[i].detail)
              : '';
            if (
              dataParse &&
              dataParse.distanceScore === distanceScore &&
              dataParse.lapTime === lapTime
            ) {
              maxValIndex = i;
            }
          }
        }
      }

      const sim_train = execution.sim
        ? _.find(
            execution.sim,
            sim =>
              (sim.index === 0 || sim.index === 1) &&
              sim.state === 2 &&
              sim.log_file_path
          )
        : null;
      return {
        id: execution.execution.regist_date,
        isFinished: execution?.sim && execution?.sim?.length === 3,
        user_id: execution.execution.user_id,
        build_id: execution.execution.build_id,
        regist_date: execution.execution.regist_date,
        distanceScore: distanceScore === -1 ? undefined : distanceScore,
        lapTime: lapTime === -1 ? undefined : lapTime,
        downloadLink: `${process.env.REACT_APP_DEV_BUCKET_DOWNLOAD}/${
          execution.execution.build_id
        }/${maxValIndex + 1}/result.json`,
        downloadMp4: `${process.env.REACT_APP_DEV_BUCKET_DOWNLOAD}/${
          execution.execution.build_id
        }/${maxValIndex + 1}/capture.mp4`,
        downloadLog: `${process.env.REACT_APP_DEV_BUCKET_DOWNLOAD}/${
          execution.execution.build_id
        }/${maxValIndex + 1}/autoware.log`,
        downloadRosbag2: `${process.env.REACT_APP_DEV_BUCKET_DOWNLOAD}/${
          execution.execution.build_id
        }/${maxValIndex + 1}/rosbag2_autoware.tar.gz`,
        status: parse_state(
          execution.build ? execution.build.state : null,
          execution.sim ? execution.sim : null,
          null
        ),
        score_train: parse_score(sim_train ? [sim_train] : null),
        score_eval: parse_score(execution.sim ? execution.sim : null),
        rosbag_bucketname:
          sim_train && sim_train.log_file_path
            ? sim_train.log_file_path.split('/')[2]
            : '',
        rosbag_filepath:
          sim_train && sim_train.log_file_path
            ? sim_train.log_file_path.split('/')[3] +
              '/' +
              sim_train.log_file_path.split('/')[4] +
              '/rosbag.tar.gz'
            : '',
        log_filepath:
          sim_train && sim_train.log_file_path
            ? sim_train.log_file_path.split('/')[3] +
              '/' +
              sim_train.log_file_path.split('/')[4] +
              '/log.txt'
            : ''
      };
    });
  const group_executions_with_results = _.map(
    joined_group_executions,
    execution => {
      const sim_train = execution.sim
        ? _.find(
            execution.sim,
            sim =>
              (sim.index === 0 || sim.index === 1) &&
              sim.state === 2 &&
              sim.log_file_path
          )
        : null;
      return {
        id: execution.execution.regist_date,
        user_id: execution.execution.user_id,
        build_id: execution.execution.build_id,
        regist_date: execution.execution.regist_date,
        status: parse_state(
          execution.build ? execution.build.state : null,
          execution.sim ? execution.sim : null,
          null
        ),
        score_train: parse_score(sim_train ? [sim_train] : null),
        score_eval: parse_score(execution.sim ? execution.sim : null),
        rosbag_bucketname:
          sim_train && sim_train.log_file_path
            ? sim_train.log_file_path.split('/')[2]
            : '',
        rosbag_filepath:
          sim_train && sim_train.log_file_path
            ? sim_train.log_file_path.split('/')[3] +
              '/' +
              sim_train.log_file_path.split('/')[4] +
              '/rosbag.tar.gz'
            : '',
        log_filepath:
          sim_train && sim_train.log_file_path
            ? sim_train.log_file_path.split('/')[3] +
              '/' +
              sim_train.log_file_path.split('/')[4] +
              '/log.txt'
            : ''
      };
    }
  );

  const revisionsSort = _.sortBy(
    my_executions_with_results,
    item => item.regist_date
  ).reverse();

  const revisions = _.filter(revisionsSort, rv => rv.build_id);
  const groupRevisions = _.filter(
    group_executions_with_results,
    rv => rv.build_id
  );
  return { revisions, groupRevisions };
}

function getUser(user_id) {
  return new Promise((resolve, reject) => {
    const params = {
      TableName: `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_user_list`,
      Key: {
        user_id
      }
    };
    dynamoClient.get(params, (error, data) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
}

async function putUser(params) {
  return new Promise((resolve, reject) => {
    dynamoClient.put(params, (error, data) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
}

function putUserConfig(courceStatusTemp) {
  return new Promise((resolve, reject) => {
    Auth.currentUserInfo().then(response => {
      if (response && response.username) {
        getUser(response.username).then(
          user => {
            user[
              'TableName'
            ] = `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_user_list`;
            // user.Item['course'] = courceStatusTemp;
            putUser(user).then(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
          },
          error => {
            // not found
            const params = {
              TableName: `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_user_list`,
              Item: {
                user_id: response.username,
                course: courceStatusTemp,
                email: response.attributes?.email || ''
              }
            };
            putUser(params).then(
              data => {
                resolve(data);
              },
              error => {
                reject(error);
              }
            );
          }
        );
      }
    });
  });
}

function getBuildRecord(build_id) {
  return new Promise((resolve, reject) => {
    const params = {
      TableName: `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_build_result`,
      Key: {
        build_id: build_id
      }
    };
    dynamoClient.get(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        const result = data.Item;
        resolve(result);
      }
    });
  });
}

function filterRanking(userList, ranking) {
  const advanced = [];
  const challenge = [];
  ranking.forEach(rank => {
    const userRank = userList.find(user => user.user_id == rank.user_id);
    if (userRank) {
      if (userRank?.course == '2') {
        challenge.push(rank);
      } else if (userRank?.course == '1') {
        advanced.push(rank);
      }
    }
  });

  // console.log("advanced: ", advanced);
  const rankingAdvanced = add_rank(advanced);
  const rankingChallenge = add_rank(challenge);
  return { rankingAdvanced, rankingChallenge };
}

async function putUploadExecution(buildRs) {
  const params = {
    TableName: `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_execution_record`,
    Item: buildRs
  };

  return new Promise((resolve, reject) => {
    dynamoClient.put(params, (error, data) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
}

async function putUploadRecord(buildRs) {
  const params = {
    TableName: `${process.env.REACT_APP_DEV_KEY_TABLE_DYNAMO_DB}_build_result`,
    Item: buildRs
  };

  return new Promise((resolve, reject) => {
    dynamoClient.put(params, (error, data) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
}

async function markInvalidBuildResult(revisions) {
  revisions.forEach(async element => {
    const buildRs = await getBuildRecord(element.build_id).catch(err => {
      return err;
    });
    buildRs['state'] = BUILD_STATE.INVALID;
    putUploadRecord(buildRs);
  });
}

export default withStyles(styles)(Dashboard);
