import React from 'react';
import {
  Card,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Radio
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
const style = {
  titleModalCard: {
    fontWeight: 700,
    padding: 12
  },
  text: {
    fontWeight: 400,
    fontSize: 14,
    color: '#3F3F3F'
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  flexContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  box: {
    border: '2px solid #B1B1B1',
    borderRadius: 10,
    padding: 5,
    width: 192,
    height: 184,
    cursor: 'pointer'
  },
  content: {
    width: 432
  },
  changeButton: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    color: '#FFFFFF',
    cursor: 'pointer'
  },
  textChange: {
    fontSize: 14,
    fontWeight: 700,
    color: '#002B52'
  },
  boxSelected: {
    border: '2px solid #004889',
    borderRadius: 10,
    padding: 5,
    width: 192,
    height: 184,
    cursor: 'pointer',
    background: '#F0F8FF'
  }
};

class ChangeCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectSource: this.props.courseStatus
    };
    this.onChangeSource = this.onChangeSource.bind(this);
    this.handleChangeSource = this.handleChangeSource.bind(this);
  }

  onChangeSource() {
    this.props.onChangeSource(this.state.selectSource);
  }
  handleChangeSource(event) {
    this.setState({ selectSource: event.target.value });
  }
  render() {
    const { classes } = this.props;
    const { selectSource } = this.state;
    return (
      <>
        <Card>
          <div
            style={{
              padding: 16,
              fontWeight: 500,
              fontSize: 20,
              color: '#1A1A1A'
            }}>
            コース切り替え
          </div>
          <CardContent>
            <div className={classes.text} style={{ marginBottom: 16 }}>
              両方のコースに同時にスコアを記録することはできませんが、途中でコースを変更したい場合は、これまで記録したスコアを取り下げてもう一方のコースに切り替えることができます。
            </div>
            <div className={classes.flexContent}>
              <div
                className={
                  this.state.selectSource === '1'
                    ? classes.boxSelected
                    : classes.box
                }>
                <div className={classes.flex}>
                  <div>
                    <Radio
                      checked={selectSource === '1'}
                      name="radio-buttons"
                      onChange={this.handleChangeSource}
                      value="1"
                    />
                  </div>
                  <div className={classes.textChange}>チャレンジコース</div>
                </div>
                <div>
                  初心者向けのコースです。シナリオ内に走行を妨げるイベントが２つ配置されています。
                </div>
              </div>
              <div
                className={
                  selectSource === '2' ? classes.boxSelected : classes.box
                }>
                <div className={classes.flex}>
                  <div>
                    <Radio
                      checked={selectSource === '2'}
                      name="radio-buttons"
                      onChange={this.handleChangeSource}
                      value="2"
                    />
                  </div>
                  <div className={classes.textChange}>アドバンストコース</div>
                </div>
                <div>
                  上級者向けのコースです。チャレンジコースに加えてイベントが２つ追加されています。
                </div>
              </div>
            </div>
          </CardContent>
          <CardActions>
            <Button
              className={classes.changeButton}
              color="primary"
              onClick={this.onChangeSource}
              style={{ width: '100%' }}
              variant="contained">
              更新
            </Button>
          </CardActions>
          <div />
        </Card>
      </>
    );
  }
}
// ChangeCourse.prototype = {
//   onChangeSource: PropTypes.func
// };

export default withStyles(style)(ChangeCourse);
