import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Tooltip, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InputIcon from '@material-ui/icons/Input';
import Setting from '../../../../views/Settings'
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import avatar from '../../../../../src/assets/avatar.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EditIcon from '@material-ui/icons/Edit';
import {
  Menu,
  MenuItem,
  Modal
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {   
    flexGrow: 1
  },
  signOutButton: {
    margin: theme.spacing(1),
    margin: 10
  },
  textName: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: '500px',
    paddingLeft: '10px',
    textAlign: 'center'
  },
  rightHeader: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  paper: {
    position: 'absolute',
    width: 360,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: theme.spacing(1),
  }
}));


const Topbar = props => {
  const { className, ...rest } = props;
  console.log('Sentry', Sentry.BrowserClient);
  const classes = useStyles();

  const [notifications] = useState([]);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    Auth.currentUserInfo().then(user => {
      setUserInfo(user);
    });
  }, []);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let [openModalProfile, setOpenModalProfile] = useState(false);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenModalProfile = () => {
    setAnchorEl(null);
    setOpenModalProfile(true)
  }

  const handleOpenModal = () => {
    setAnchorEl(null);
    setOpenModalProfile(true);
  }

  const handleCloseModalProfile = () => {
    setOpenModalProfile(false)
  }

  const getStyleAvatar = () => {
    return {
      backgroundImage: `url(${avatar})`,
      width: 32,
      height: 32,
      borderRadius: '50%',
      cursor: 'pointer',
      backgroundSize: 'contain'
    };
  }

  const getBackground = () => {
    return {
      backgroundColor: '#002B52'
    };
  }

  const closeModal = () => {
    setOpenModalProfile(false);
  };

  const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      position: 'absolute'
    };
  }

  const signOut = () => {
    setAnchorEl(null);
    Auth.signOut()
      .then(() => {
        Sentry.configureScope(scope => scope.setUser(null));
      })
      .catch(error => {
        console.error('when sign out', error);
      });
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar style={getBackground()}>
        <RouterLink to="/">
          <span style={{ color: 'white', fontSize: 20, fontWeight: 500 }}>AI CHALLENGE</span>
        </RouterLink>
        <div className={classes.flexGrow} />
        <div
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          className={classes.rightHeader}
          onClick={handleClick}
        >
          <div style={getStyleAvatar()} />
          <div className={classes.textName} />
          {userInfo?.username}
        </div>

        <Menu
          anchorEl={anchorEl}
          className={classes.menu}
          id="basic-menu"
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          onClose={handleClose}
          open={open}
          style={{ top: '48px' }}
        >
          <MenuItem onClick={handleOpenModal}>
            <EditIcon/>
            <span 
              style={{
                fontWeight: 700,
                fontSize: 14
              }}
            >
                パスワードの変更
            </span>
          </MenuItem>
          <MenuItem onClick={signOut}>
            <ExitToAppIcon/>
            <span 
              style={{
                fontWeight: 700,
                fontSize: 14
              }}
            >
                ログアウト
            </span>
          </MenuItem>
        </Menu>
        <div
          className={classes.paper}
          style={getModalStyle()}
        >
          <Modal
            aria-describedby="simple-modal-description"
            aria-labelledby="simple-modal-title"
            onClose={handleCloseModalProfile}
            open={openModalProfile}
          >
            <Setting closeModal={closeModal} />
          </Modal>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
