/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';

import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/browser';
Amplify.configure({
  Auth: {
    region:              process.env.REACT_APP_DEV_REGION,  // REQUIRED - Amazon Cognito Region
    userPoolId:          process.env.REACT_APP_DEV_USER_POOL_ID,  //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_DEV_USER_POOL_WEB_CLIENT_ID,  //OPTIONAL - Amazon Cognito Web Client ID
    identityPoolId:      process.env.REACT_APP_DEV_IDENTITY_POOL_ID,
    mandatorySignIn:     true,
  },
  Storage: {
    bucket: process.env.REACT_APP_DEV_BUCKET,
    region: process.env.REACT_APP_DEV_REGION,
  }
});
/*
// dev
Amplify.configure({ 
  Auth: {
    region:              'ap-northeast-1',  // REQUIRED - Amazon Cognito Region
    userPoolId:          'ap-northeast-1_WWbmy4rnT',  //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '7j350u5o04vlgkgm59k8bsmlq2',  //OPTIONAL - Amazon Cognito Web Client ID
    identityPoolId:      'ap-northeast-1:d69c393e-28e2-4b8a-9936-33bceea72861',
    mandatorySignIn:     true,
  },
  Storage: {
    bucket: 'tier4-tools-dev-simulator-env-register',
    region: 'ap-northeast-1',
  }
});
*/
Sentry.init({
  dsn:         'https://4e4c8ece12c54172a446dc7518a4b581@sentry.io/3693264',
  environment: 'prod',
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
