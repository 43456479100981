/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import _ from 'underscore';
export const STATUS = {
  READY: 'ready',
  UPLOADING: 'uploading',
  UPLOADING_FINISHED: 'uploading_finished',

  BUILDING: 'building', // build_result.state == 0
  BUILD_COMPLETED: 'build_completed', // build_result.state == 1

  STARTING: 'starting', // simulator_execution_result.state == 0
  RUNNING: 'running', // simulator_execution_result.state == 1
  FINISHED: 'finished', // simulator_execution_result.state == 2

  BUILD_ERROR: 'build_error', // build_result.state == 10
  STARTUP_ERROR: 'startup_error', // simulator_execution_result.state == 10
  RUNNING_ERROR: 'running_error', // simulator_execution_result.state == 11
  INVALID: 'invalid',

  RUNNING_TIMEOUT: 'running_timeout' // simulator_execution_result.state == 12
};
export const STATUS_COLORS = {
  [STATUS.READY]: 'success',
  [STATUS.FINISHED]: 'success',

  [STATUS.UPLOADING]: 'info',
  [STATUS.UPLOADING_FINISHED]: 'info',
  [STATUS.BUILDING]: 'info',
  [STATUS.BUILD_COMPLETED]: 'info',
  [STATUS.STARTING]: 'info',
  [STATUS.RUNNING]: 'info',

  [STATUS.BUILD_ERROR]: 'danger',
  [STATUS.STARTUP_ERROR]: 'danger',
  [STATUS.RUNNING_ERROR]: 'danger',
  [STATUS.RUNNING_TIMEOUT]: 'danger',
  [STATUS.INVALID]: 'danger'
};
export const STATUS_TEXTS = {
  [STATUS.READY]: 'アップロード可能',
  [STATUS.FINISHED]: '採点完了',

  [STATUS.UPLOADING]: 'アップロード中',
  [STATUS.UPLOADING_FINISHED]: 'アップロード完了',
  [STATUS.BUILDING]: 'ビルド中',
  [STATUS.BUILD_COMPLETED]: 'ビルド完了',
  [STATUS.STARTING]: 'シミュレーター起動中',
  [STATUS.RUNNING]: 'シミュレーターテスト中',

  [STATUS.BUILD_ERROR]: 'ビルドエラー',
  [STATUS.STARTUP_ERROR]: 'シミュレーターエラー',
  [STATUS.RUNNING_ERROR]: '結果なし',
  [STATUS.RUNNING_TIMEOUT]: 'タイムアウト',
  [STATUS.INVALID]: '有効データがありません。'
};

export const RESULT = {
  // detailがない
  INVALID: 'invalid',

  // isHitObject == True
  HIT_OBJECT: 'hit_object',
  // isRootIgnore == True ルート無視
  ROOT_IGNORE: 'root_ignore',
  // isOverSpeed == True 速度超過
  OVER_SPEED: 'over_speed',
  // isGoal == False ゴール未到達
  GOAL_UNREACHED: 'goal_unreached',
  // isPassAllWayPoint == False
  NOT_PASSED_ALL_WAYPOINT: 'not_passed_all_waypoint',

  // 上記に当てはまらない
  OK: 'ok'
};
export const RESULT_TEXT = {
  [RESULT.INVALID]: '結果なし',
  [RESULT.HIT_OBJECT]: '他オブジェクトと衝突',
  [RESULT.ROOT_IGNORE]: '路面外走行',
  [RESULT.OVER_SPEED]: '速度超過',
  [RESULT.GOAL_UNREACHED]: 'ゴール未到達',
  [RESULT.NOT_PASSED_ALL_WAYPOINT]: 'チェックポイント未通過',
  [RESULT.OK]: '正常'
};

export const BUILD_STATE = {
  INVALID: 13
};

export const detail_to_result = detail => {
  if (detail && Object.keys(detail).length !== 0) {
    const rsFilter = detail
      .replaceAll('\n', '')
      .replaceAll('"', '')
      .replaceAll('{', '')
      .replaceAll('}', '')
      .replaceAll(' ', '')
      .split(',')
      .map(e => {
        return e.trim();
      });
    const objDetail = {};
    rsFilter
      .filter(e => e.indexOf(':'))
      .forEach(e => {
        objDetail[e.split(':')[0]] = e.split(':')[1];
      });
    const time =
      parseInt(objDetail.score) >= 0
        ? parseInt(objDetail.score)
        : parseInt(objDetail.score) + 24 * 60 * 60;
    if (objDetail.isHitObject === 'True') {
      return { result: RESULT.HIT_OBJECT, time: time };
    } else if (objDetail.isRootIgnore === 'True') {
      return { result: RESULT.ROOT_IGNORE, time: time };
    } else if (objDetail.isOverSpeed === 'True') {
      return { result: RESULT.OVER_SPEED, time: time };
    } else if (objDetail.isGoal === 'False') {
      return { result: RESULT.GOAL_UNREACHED, time: time };
    } else if (objDetail.isPassAllWayPoint === 'False') {
      return { result: RESULT.NOT_PASSED_ALL_WAYPOINT, time: time };
    } else {
      return { result: RESULT.OK, time: time };
    }
  } else {
    return { result: RESULT.INVALID };
  }
};

export const parse_state = (
  build_state,
  simulator_execution_states,
  upload_state
) => {
  // console.log('simulator_execution_states', simulator_execution_states);
  // console.log('build_state', build_state);
  /*
    // 開始した？ -> build_completed
    // 全部が終了(FINISHED, ERROR)した？
    // no: RUNNING
    // yes: ひとつでも成功しているものがある？
    // -> 成功
    // -> シミュレーターエラー
    */
  if (build_state === null) {
    return STATUS.UPLOADING_FINISHED;
  } else if (build_state === BUILD_STATE.INVALID) {
    return STATUS.INVALID;
  } else if (build_state === 0) {
    return STATUS.BUILDING;
  } else if (build_state === 1) {
    if (
      simulator_execution_states === null ||
      simulator_execution_states.length === 0
    ) {
      return STATUS.BUILD_COMPLETED;
    } else {
      const is_all_simulation_finished = _.all(
        simulator_execution_states,
        s => s.state === 2 || s.state === 10 || s.state === 11 || s.state === 12
      );
      // const is_any_train_simulation_succeeded = _.any(
      //   simulator_execution_states.filter(state => state.index === 0 || state.index === 1), s => s.state === 2
      // )
      const is_any_eval_simulation_succeeded = _.any(
        simulator_execution_states,
        s => s.state === 2
      );
      const is_all_eval_simulation_timeout = _.all(
        simulator_execution_states,
        s => s.state === 12
      );
      if (!is_all_simulation_finished) {
        return STATUS.RUNNING;
      } else {
        if (is_any_eval_simulation_succeeded) {
          return STATUS.FINISHED;
        } else if (is_all_eval_simulation_timeout) {
          return STATUS.RUNNING_TIMEOUT;
        } else {
          return STATUS.RUNNING_ERROR;
        }
      }
    }
  } else if (build_state === 10) {
    return STATUS.BUILD_ERROR;
  }
};

export const COURSE_STATUS = {
  CHALLENGE: '1',
  ADVANCED: '2'
};
export const COURSE_NAME = {
  [COURSE_STATUS.CHALLENGE]: 'チャレンジコース',
  [COURSE_STATUS.ADVANCED]: 'アドバンストコース'
};

export const DATA_SAMPLE_CHALLENGE = [
  {
    regist_date: '2022-10-11 00:19',
    status: 'ready',
    score_train: {
      result: 'ok'
    }
  },
  {
    regist_date: '2022-10-11 00:19',
    status: 'finished',
    score_train: {
      result: 'ok',
      time: 3
    },
    score_eval: {
      result: 'ok',
      time: 3
    }
  },
  {
    regist_date: '2022-10-11 00:19',
    status: 'finished',
    score_train: {
      result: 'ok',
      time: 15
    },
    score_eval: {
      result: 'ok',
      time: 12
    },
    rosbag_filepath: 'sample',
    rosbag_bucketname: 's3'
  },
  {
    regist_date: '2022-10-11 00:19',
    status: 'build_error'
  },
  {
    regist_date: '2022-10-11 00:19',
    status: 'running'
  }
];

export const DATA_SAMPLE_ADVANCED = [
  {
    regist_date: '2022-24-12 00:19',
    status: 'building',
    score_train: {
      result: 'ok'
    }
  },
  {
    regist_date: '2022-24-12 00:19',
    status: 'finished',
    score_train: {
      result: 'ok',
      time: 3
    },
    score_eval: {
      result: 'ok',
      time: 3
    }
  },
  {
    regist_date: '2022-24-12 00:19',
    status: 'finished',
    score_train: {
      result: 'ok',
      time: 15
    },
    score_eval: {
      result: 'ok',
      time: 12
    },
    rosbag_filepath: 'sample',
    rosbag_bucketname: 's3'
  },
  {
    regist_date: '2022-24-12 00:19',
    status: 'startup_error'
  },
  {
    regist_date: '2022-24-12 00:19',
    status: 'running_error'
  }
];
